<template>
  <div
    v-if="!$apollo.queries.loading && advertiserCampaign"
    id="campaign-page"
    class=""
  >
    <b-container class="bg-white flex-fill p-2">
      <h2>{{ advertiserCampaign.name }}</h2>
      <p>{{ advertiserCampaign.detailVenueText }}</p>

      <b-form v-show="success !== true" @submit.prevent="onSubmit" class="mt-5">
        <b-form-group label="Nom">
          <b-input v-model="name" autocomplete="campaign-name"></b-input>
          <b-form-invalid-feedback v-if="error.name" :state="false">
            {{ error.name }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Prénom">
          <b-input
            v-model="firstName"
            autocomplete="campaign-firstname"
          ></b-input>
          <b-form-invalid-feedback v-if="error.firstName" :state="false">
            {{ error.firstName }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Email">
          <b-input v-model="email" autocomplete="campaign-email"></b-input>
          <b-form-invalid-feedback v-if="error.email" :state="false">
            {{ error.email }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Téléphone">
          <b-input v-model="phone" autocomplete="campaign-phone"></b-input>
          <b-form-invalid-feedback v-if="error.phone" :state="false">
            {{ error.phone }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button variant="primary" block type="submit">Valider</b-button>
      </b-form>
      <b-alert
        v-if="success === true"
        variant="success"
        show
        class="mt-3"
        :dismissible="true"
        @dismissed="$router.push({ name: 'list' })"
      >
        Suite à votre demande, vous allez être contacté rapidement
        <div class="text-center">
          <b-link :to="{ name: 'list' }" class="d-block">Fermer </b-link>
        </div>
      </b-alert>
    </b-container>
  </div>
  <div
    v-else
    class="h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <loader></loader>
  </div>
</template>
<script>
import Me from "@/mixins/me"
import ADVERTISER_CAMPAIGN from "@/graphql/AdvertiserCampaignByName.gql"
import STORE_ADVERTISER_CAMPAIGN_REGISTRATION from "@/graphql/mutations/StoreAdvertiserCampaignRegistration.gql"
import { first } from "@/utils"
import Loader from "@/components/Loader"
export default {
  components: {
    Loader
  },
  mixins: [Me],
  data() {
    return {
      name: "",
      firstName: "",
      email: "",
      phone: "",
      error: {},
      success: null
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    advertiserCampaign: {
      query: ADVERTISER_CAMPAIGN,
      variables() {
        return {
          name: this.$route.params.campaign
        }
      },
      update: data => data.advertiserCampaignByName
    }
  },
  created() {
    this.$apollo.queries.me.skip = false
    this.$apollo.queries.me.refetch().then(() => {
      this.name = this.me.name
      this.firstName = this.me.firstName
      this.email = this.me.email
      this.phone = this.me.phone
    })
  },
  mounted() {},
  methods: {
    onSubmit(data) {
      return new Promise((resolve, reject) => {
        this.error = {}
        const data = {
          name: this.name,
          firstName: this.firstName,
          email: this.email,
          phone: this.phone,
          advertiserCampaignId: this.advertiserCampaign.id
        }
        this.loading = true
        this.$apollo
          .mutate({
            mutation: STORE_ADVERTISER_CAMPAIGN_REGISTRATION,
            variables: data
          })
          .then(response => {
            this.loading = false
            this.success = response.data.storeAdvertiserCampaignRegistration
          })
          .catch(error => {
            this.loading = false
            this.error = {}
            if (error.graphQLErrors) {
              let validation = first(error.graphQLErrors).extensions.validation
              if (validation) {
                for (let k in validation) {
                  this.error[k] = first(validation[k])
                }
              }
            }
          })
      })
    }
  }
}
</script>
